import React from "react";
import { Info } from "lucide-react";
import Navbar from "./Navbar";

const WAPrivacy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Privacy Policy
        </h1>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            <Info className="mr-2 text-blue-400" />
            WhatsAnalyse Privacy Policy
          </h2>
          <div className="text-gray-300 whitespace-pre-wrap">
            <p>
              <strong>Last updated: December 16, 2024</strong>
            </p>
            <p>
              Thank you for using WhatsAnalyse ("we," "our," or "us"). This
              privacy policy explains how we collect, use, and protect your
              personal information in compliance with applicable privacy laws,
              including the General Data Protection Regulation (GDPR) and the
              California Consumer Privacy Act (CCPA).
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              1. Data Controller Information
            </h3>
            <p>
              WhatsAnalyse is the data controller for the personal data
              processed through this app. You can contact us at:
              <br />- <strong>Name:</strong> Jan Christian Swoboda
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
              <br />- <strong>EU Representative:</strong> the above.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              2. Categories of Personal Information We Collect
            </h3>
            <p>
              We collect and process the following categories of personal
              information:
              <br />- <strong>Identifiers:</strong> device identifiers, IP
              address
              <br />- <strong>Authentication Data:</strong> Information provided
              through Apple or Google login
              <br />- <strong>WhatsApp Chat Data:</strong> Message content
              analyzed using GPT-4o-mini API (the request goes out directly from
              your device, we are not intermediary in this exchange and store
              nothing on any of our servers.)
              <br />- <strong>Usage Data:</strong> App interaction data,
              analytics, and crash reports through Firebase
              <br />- <strong>Technical Data:</strong> Browser type, operating
              system, device information
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              3. How We Collect Information
            </h3>
            <p>
              We collect information:
              <br />- Directly from you when you use our app or create an
              account
              <br />- Through automated technologies or interactions (Firebase)
              <br />- From third-party services (Apple, Google)
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              4. How We Use Your Information
            </h3>
            <p>
              We use your information for:
              <br />- Providing and improving our services
              <br />- Authentication and account management
              <br />- Chat analysis and insights generation
              <br />- Service optimization and debugging
              <br />- Legal compliance and protection of rights The legal bases
              for processing your data under GDPR include:
              <br />- Contract performance
              <br />- Legal obligations
              <br />- Legitimate interests
              <br />- Consent (where applicable)
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              5. Data Retention and Storage
            </h3>
            <p>
              - WhatsApp chat data is processed locally to the third-party API
              and not stored on our servers
              <br />- Account information is retained while your account is
              active
              <br />- Analyzed responses are stored for about for 30 days by
              OpenAI. Please refer to their privacy policy.
              (https://openai.com/policies/row-privacy-policy/)
              <br />- We delete personal data when no longer needed for the
              purposes collected
              <br />- You can request deletion of your data at any time
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              6. Information Sharing and Disclosure
            </h3>
            <p>
              We share your information with:
              <br />- GPT-4o-mini: For chat analysis
              <br />- Firebase: For essential app services
              <br />- Service providers: For app functionality and maintenance
              <br />- Legal authorities: When required by law. We do not sell
              personal information as defined under the CCPA.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              7. Your Privacy Rights
            </h3>
            <p>
              Under GDPR and CCPA, you have the right to:
              <br />- Access your personal information
              <br />- Correct inaccurate data
              <br />- Delete your personal information
              <br />- Restrict or object to processing
              <br />- Data portability
              <br />- Withdraw consent
              <br />- Non-discrimination for exercising rights
              <br />- Opt-out of sales (though we don't sell personal
              information) California residents can exercise their rights by:
              <br />- Emailing kontakt@bottwarbytes.de
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              8. Data Security
            </h3>
            <p>
              We implement appropriate technical and organizational measures
              including:
              <br />- Encryption of personal data
              <br />- Regular security assessments
              <br />- Access controls and authentication
              <br />- Security incident response procedures
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              9. International Data Transfers
            </h3>
            <p>
              When we transfer data outside the EEA or your region, we ensure
              appropriate safeguards through:
              <br />- Standard contractual clauses
              <br />- Adequacy decisions
              <br />- Other legal transfer mechanisms You can request
              information about these safeguards by contacting us.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              10. Children's Privacy
            </h3>
            <p>
              Our service is not directed to children under 16. We do not
              knowingly collect personal information from children. If you
              believe we have collected information from a child, please contact
              us.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              11. Cookies and Tracking Technologies
            </h3>
            <p>
              We use cookies and similar technologies for:
              <br />- Essential app functionality
              <br />- Analytics and performance
              <br />- User preferences You can manage tracking preferences
              through your device settings.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              12. Changes to This Policy
            </h3>
            <p>
              We may update this policy periodically. We will notify you of
              material changes through the app or by email. Continued use of our
              services after changes constitutes acceptance of the updated
              policy.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              13. Contact Information
            </h3>
            <p>
              For privacy-related inquiries:
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
              <br />- <strong>Phone:</strong> +49 152 23132471 For EU residents:
              <br />- You can also contact your local data protection authority
            </p>
          </div>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. All rights reserved.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WAPrivacy;
