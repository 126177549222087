import React from "react";
import { Info } from "lucide-react";
import Navbar from "./Navbar";

const WATos = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Terms of Service
        </h1>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            <Info className="mr-2 text-blue-400" />
            WhatsAnalyse Terms of Service
          </h2>
          <div className="text-gray-300 whitespace-pre-wrap">
            <p>
              <strong>Last updated: December 16, 2024</strong>
            </p>
            <p>
              Welcome to WhatsAnalyse ("we," "our," "us," or the "Service").
              These Terms of Service ("Terms") constitute a legally binding
              agreement between you and BottwarBytes GbR governing your use of
              our app and services. By accessing or using WhatsAnalyse, you
              agree to these Terms. If you disagree with any part of these
              Terms, you may not access the Service.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              1. Definitions
            </h3>
            <p>
              - "Service" refers to the WhatsAnalyse application, including all
              features, functionalities, and user interfaces.
              <br />- "User," "you," and "your" refer to any individual or
              entity using our Service.
              <br />- "Content" refers to text, data, information, and other
              materials processed through our Service.
              <br />- "Analysis Results" refers to the outputs generated by our
              Service based on your Content.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              2. Eligibility and Account Registration
            </h3>
            <p>
              2.1. Age Requirement: You must be at least 16 years old to use our
              Service. If you are between 16 and 18 years old, you represent
              that you have your parent's or legal guardian's permission to use
              the Service. 2.2. Account Registration: You may need to create an
              account using Apple or Google authentication. You agree to:
              <br />- Provide accurate and complete information
              <br />- Maintain the security of your account
              <br />- Promptly update any changes to your information
              <br />- Accept responsibility for all activities under your
              account
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              3. Service Description and License
            </h3>
            <p>
              3.1. Service Description: WhatsAnalyse provides chat analysis
              services using GPT-4o-mini, processing WhatsApp chat data locally
              on your device. 3.2. License Grant: We grant you a limited,
              non-exclusive, non-transferable, and revocable license to use our
              Service for personal, non-commercial purposes, subject to these
              Terms. 3.3. Restrictions: You may not:
              <br />- Reverse engineer or decompile the Service
              <br />- Remove any copyright or proprietary notices
              <br />- Use the Service for any illegal or unauthorized purpose
              <br />- Transfer your account to another person
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              4. User Content and Data Processing
            </h3>
            <p>
              4.1. Content Ownership: You retain all rights to your Content. By
              using our Service, you grant us the necessary rights to:
              <br />- Process your Content locally for analysis
              <br />- Store Analysis Results
              <br />- Use anonymized data for Service improvement 4.2. Content
              Restrictions: You agree not to submit Content that:
              <br />- Violates any laws or regulations
              <br />- Infringes on others' rights
              <br />- Contains malicious code or harmful content 4.3. Data
              Processing:
              <br />- Chat data is processed locally on your device
              <br />- Analysis is performed using GPT-4o-mini
              <br />- Only Analysis Results are stored on our servers
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              5. Privacy and Data Protection
            </h3>
            <p>
              Your privacy is important to us. Our processing of personal data
              is governed by our Privacy Policy, which is incorporated into
              these Terms by reference.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              6. Intellectual Property Rights
            </h3>
            <p>
              6.1. Our Rights: We retain all rights to:
              <br />- The Service's software and technology
              <br />- Our trademarks and branding
              <br />- The Analysis Results format and presentation 6.2.
              Feedback: Any feedback you provide about the Service may be used
              without restriction or compensation.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              7. Payment Terms
            </h3>
            <p>
              7.1. Free and Paid Services: We may offer both free and paid
              features. Paid features will be clearly marked. 7.2. Payment
              Processing: Payments are processed through authorized third-party
              providers. 7.3. Refunds: Refund policies will be specified for
              each paid feature.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              8. Disclaimers and Warranties
            </h3>
            <p>
              8.1. Service Provided "As Is": The Service is provided without
              warranties of any kind, either express or implied. 8.2. Accuracy:
              While we strive for accuracy, we do not guarantee the completeness
              or accuracy of Analysis Results. 8.3. Availability: We do not
              guarantee uninterrupted or error-free service.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              9. Limitation of Liability
            </h3>
            <p>
              9.1. To the maximum extent permitted by law, we shall not be
              liable for:
              <br />- Direct, indirect, or consequential damages
              <br />- Loss of data or profits
              <br />- Service interruptions
              <br />- Accuracy of Analysis Results 9.2. Total Liability: Our
              total liability shall not exceed the amount you paid for the
              Service in the past 12 months.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              10. Term and Termination
            </h3>
            <p>
              10.1. Term: These Terms remain in effect while you use the
              Service. 10.2. Termination: We may terminate or suspend your
              access:
              <br />- For Terms violations
              <br />- For illegal or harmful activities
              <br />- At our discretion with notice 10.3. Effect of Termination:
              <br />- Access to the Service will cease
              <br />- Certain Terms provisions survive termination
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              11. Changes to Terms
            </h3>
            <p>
              11.1. We may modify these Terms at any time. Changes will be
              effective upon posting. 11.2. Your continued use of the Service
              constitutes acceptance of modified Terms. 11.3. Material changes
              will be notified through the Service or email.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              12. Governing Law and Jurisdiction
            </h3>
            <p>
              12.1. These Terms are governed by German law. 12.2. Disputes will
              be resolved exclusively in the courts of Wiesbaden, Germany. 12.3.
              If any provision is found invalid, the remaining provisions stay
              in effect.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              13. Contact Information
            </h3>
            <p>
              For questions about these Terms, contact us at:
              <br />- <strong>Company:</strong> BottwarBytes GbR
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
            </p>
          </div>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. All rights reserved.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WATos;
